import React, { useState, useEffect, useRef } from 'react';
import './ScientificCalculator.css';
import { create, all } from 'mathjs';
import katex from 'katex';

const config = { }
const math = create(all, config)
const ScientificCalculator = () => {
  const [expression, setExpression] = useState('');
  const [result, setResult] = useState('');
  const [buttonClickHistory, setButtonClickHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('tab2');
  const prevButtonClickRef = useRef(null);
  const [memory, setMemory] = useState([]);
  const [rmemory, setRmemory] = useState('');
  const [mr, setMr] = useState('');
  const [angleUnit, setAngleUnit] = useState(localStorage.getItem('angleUnit') || 'rad'); // Retrieve from local storage or default to 'rad'
  const LatexOutput = () => {
    try {
      let latex = '0'; 
      if (expression.trim() !== '') {
        latex = math.parse(expression).toTex();
      }
      const mathML = katex.renderToString(latex, { output: 'mathml',throwOnError: false });
      const withoutKatexClass = mathML.replace(' class="katex"', '');
      return <div dangerouslySetInnerHTML={{ __html: withoutKatexClass }} />;
    } catch (error) {
      return expression; 
    }
  };
  
  const calculateTotalMemory = () => {
    const total = memory.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return total;
};

useEffect(() => {
    const totalMemory = calculateTotalMemory();
    setRmemory(totalMemory);
}, [memory]);
  const handleKeyPress = (event) => {
    
    
    if (event.key){
      const key = event.key;
      setButtonClickHistory((prevClicks) => [...prevClicks, key]);
    const lastPressedKey = buttonClickHistory[buttonClickHistory.length - 1];
    if(lastPressedKey === 'o'){
      event.preventDefault();
      setExpression((prevExpression) => prevExpression.replace('o','')+key);
    }
    if (key.match(/[0-9.,+\-*/.^()%]/)) {
      event.preventDefault();
      handleButtonClick(key);
    } else if (key === 'Enter' || key === '=') {
      event.preventDefault();
      handleCalculate();
    } else if (key === 'Backspace') {
      let bre = expression.slice(-1)
      let closebr = expression
      if (bre === ')' && closebr !== ')'){
        event.preventDefault();
        setExpression((prevExpression) => prevExpression.slice(0, -2) + prevExpression.slice(-1));
      }
     
      else{
        event.preventDefault();
        setExpression((prevExpression) => prevExpression.slice(0, -1));
      }
    } 
    
    else {
      
    }}
    else{
      const key = event
      setButtonClickHistory((prevClicks) => [...prevClicks, key]);
      const lastPressedKey = buttonClickHistory[buttonClickHistory.length - 1];
    if(lastPressedKey === 'o'){
      setExpression((prevExpression) => prevExpression.replace('o','')+key);
    }
      if (key === 'M+') {
        if (memory.length > 0) {
          const newMemoryValue = parseFloat(result);
          setMemory(prevMemory => [...prevMemory, newMemoryValue]);
        } else {
          setMemory([parseFloat(result)]);
        }
      }
        
    if (key === 'M-') {
        const updatedMemory = memory.slice(0, -1);
        const up = memory.slice(-1);
        setMr(up)
        setMemory(updatedMemory);
    }
    if (key === 'MR') {
      if (mr !== null) {
        const newMemoryValue = parseFloat(mr);
          setMemory(prevMemory => [...prevMemory, newMemoryValue]);
      }
  }
  if (key === 'back'){
  let bre = expression.slice(-1)
  let closebr = expression
  if (bre === ')' && closebr !== ')'){
    setExpression((prevExpression) => prevExpression.slice(0, -2) + prevExpression.slice(-1));
  }
 
  else{
    setExpression((prevExpression) => prevExpression.slice(0, -1));
  }}
  if (key === '-1*'){
    if (expression.slice(0) !== '-'){
      setExpression("-"+expression);}
    }
    
  }};
useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [expression,buttonClickHistory]);
  
const handleButtonClick = (value) => {
  handleKeyPress(value);
  console.log(value)
  const lastPressedKey = buttonClickHistory[buttonClickHistory.length - 1];
  prevButtonClickRef.current = lastPressedKey;
  setButtonClickHistory((prevClicks) => [...prevClicks, value]);

const isNumber = value.match(/^(?!(?:M[+\-]|MR|MC)$)(?:[0-9.,+\-*/]|pi|e|e\^|10\^|\^|\^\(3\)|\^\(2\)|\%|\(\)|1\/\(\)|\*10\^)$/);
const isFunctionMatch = value.match(/^(?!pi$|e$|e\^$|M\+$|M\-$|MR$|back$)(?:1\/\(|erf\()?[a-z]/i);
const isFunMatch = value.match(/^(?!sin\(|cos\(|tan\(|pi$|e$|e\^$|M\+$|M\-$|MR$|back$)(?:1\/\(|sqrt\(|asin\(|acos\(|atan\(|cbrt\(|erf\()?[a-z]/i);


const isTrigonoMatch = value.match(/^(sin\(|cos\(|tan\()$/i);
const elements = buttonClickHistory.map((item, index) => (
  <li key={index}>{item}</li>
));



const all_func_c = elements.filter(element => {
  return element.props.children.match(/[a-z]/i);
}).length;
const lastFunctionk = buttonClickHistory.reverse().find(item => item.match(/[a-z]/i));
const last_function = lastFunctionk ? 1 : 0;
const element = buttonClickHistory.map((item, index) => (
  <li key={index}>{item}</li>
));
const reversedText = element.reverse().map(elemen => elemen.props.children).join('');
const match = reversedText.match(/\)+$/);
const closingParenthesesCount = match ? match[0].length : 0;

const countContinuousClosingParentheses = (input) => {
  let count = 0;
  for (let i = input.length - 1; i >= 0; i--) {
    if (input[i] === ')') {
      count++;
    } else {
      break; 
    }
  }
  return count;
};
const elementsWithIndices = buttonClickHistory.map((item, index) => ({
  ...item,
  value: item,
  index: index
}));
const elementsWithMatch = elementsWithIndices.map(element => ({
  ...element,
  isTrigonoMatch: element.value.match(/^(sin\(|cos\(|tan\()$/i) !== null
}));
const trigonoMatchCount = elementsWithMatch.filter(element => element.isTrigonoMatch).length;
const lastFunctionMatch = typeof expression === 'string' ? expression.match(/.*?\(/g) : null;
const lastkFunction = lastFunctionMatch ? lastFunctionMatch.pop() : null;
const conti_para = lastkFunction ? countContinuousClosingParentheses(expression) : 0;
if (angleUnit === 'deg'){
    if (isFunMatch) {
      if (all_func_c > 0) {
        const trimmedExpression = expression.slice(0, -`${conti_para}`);
        setExpression(trimmedExpression + value + ')'.repeat(conti_para+1));
      setResult('');
      }
      else {
        setExpression((prevExpression) => prevExpression + value + ")");
        setResult('');
      }
    }
    else if (isTrigonoMatch) {
      
      if (all_func_c > 0) {
        const trimmedExpression = expression.replace(/deg/g, '').slice(0, -`${conti_para}`);
        setExpression(trimmedExpression + value + 'deg)'.repeat(conti_para+1));
      setResult('');
      }
      else {
        setExpression((prevExpression) => prevExpression.replace(/deg/g, '') + value + "deg)");
        setResult('');
      }
    }
    else  {
      if (trigonoMatchCount>0){
      if (conti_para){
        const trimmedExpression = expression.replace(/deg/g, '').slice(0, -`${conti_para}`);
        setExpression(trimmedExpression + value + 'deg)'.repeat(conti_para));
        setResult('');        
      }
      else{
        if(isNumber){
        setExpression(prevExpression => prevExpression.replace(/deg/g, '') + value);
        
        }setResult('');
    }}
    else{
      if (conti_para){
        const trimmedExpression = expression.slice(0, -`${conti_para}`);
        setExpression(trimmedExpression + value + ')'.repeat(conti_para));
        setResult('');        
      }
      else{
        if (isNumber){
      setExpression((prevExpression) => prevExpression + value);}
      setResult('');
    }
    }
    }
  }

  else {

    if (isFunctionMatch) {
      if (all_func_c > 0) {

        const trimmedExpression = expression.slice(0, -`${conti_para}`);
        setExpression(trimmedExpression + value + ')'.repeat(conti_para+1));
      setResult('');
      }
      else {
        setExpression((prevExpression) => prevExpression + value + ")");
        setResult('');
      }
    }
    if (isNumber) {
      if (conti_para){
        const trimmedExpression = expression.slice(0, -`${conti_para}`);
        setExpression(trimmedExpression + value + ')'.repeat(conti_para));
        setResult('');        
      }
      else{
      setExpression((prevExpression) => prevExpression + value);
      setResult('');
    }
    }
  }
  }; 
  
  useEffect(() => {
    
    try {

      const result = math.evaluate(expression);
      setResult(result.toString());
    } catch (error) {
      let errorMessage = 'Error';
      if (error instanceof SyntaxError) {
        errorMessage = '';
      } else if (error instanceof ReferenceError) {
        errorMessage = 'Reference Error';
      } else if (error instanceof TypeError) {
        errorMessage = '';
      } else if (error instanceof EvalError) {
        errorMessage = 'Evaluation Error';
      }
      setResult(errorMessage);
    }
  }, [expression]);
  
  const handleCalculate = () => {
    try {
      const resultRadians = math.evaluate(expression);
      setResult(resultRadians.toString());
      setExpression(resultRadians);
    } catch (error) {
      console.error('Error during evaluation:', error);
      let errorMessage = 'Error';
      if (error instanceof SyntaxError) {
        errorMessage = '';
      } else if (error instanceof ReferenceError) {
        errorMessage = 'Reference Error';
      } else if (error instanceof TypeError) {
        errorMessage = 'Type Error';
      } else if (error instanceof EvalError) {
        errorMessage = 'Evaluation Error';
      }
      setResult(errorMessage);
    }
    
  };
  
  
  
  
  const handleClear = () => {
    setExpression('');
    setResult('');
    setButtonClickHistory([]);
  };
  const MClear = () => {
    setRmemory('');
    setMemory([]);
    setMr();
  }
  useEffect(() => {
    const savedActiveTab = localStorage.getItem('activeTab');
    if (savedActiveTab) {
      setActiveTab(savedActiveTab);
    }
  }, []);
  const openTab = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem('activeTab', tabName);
  };
  const handleAngleUnitChange = (event) => {
    const selectedUnit = event.target.value;
    setAngleUnit(selectedUnit);
    localStorage.setItem('angleUnit', selectedUnit);
  };
  return (

    <div className="calculator flex-row justify-center bg-white-1 sm-w-100 md-block mp3">
      <div className="display">
      <div className="btn-md">
        <LatexOutput/>
        </div>
      <div className="bg-color text-white-2 box btn-md">{result}</div>
  
      <div className="bg-white-2 text-color box btn-md">
      {memory && memory.map((item, index) => (
    <span key={index} className={index % 2 === 0 ? 'mp3 text-black-1' : 'mp3 text-color'}>
        {index !== 0 && ' +'} {item}
    </span>
))}
{rmemory > 0 && (
    <span> = {rmemory} <i>M</i></span>
)}

</div>






      </div>
      <div className="flex justify-start bg-white-2 text-black-1">
      <div className={`cursor-pointer flex justify-between sm-gap bg-white-1 btn-sm ${angleUnit === 'rad' ? 'checked' : 'bg-white-2 cursor-pointer'}`}>
    <input
      type="checkbox"
      id="rad"
      name="angleUnit"
      value="rad"
      checked={angleUnit === 'rad'}
      onChange={handleAngleUnitChange}
      className='cursor-pointer'
    />
    <label htmlFor="rad" className='cursor-pointer'>Rad</label>
  </div>
      
  
  <div className={`cursor-pointer flex justify-between sm-gap bg-white-1 btn-sm ${angleUnit === 'deg' ? 'checked' : 'bg-white-2 cursor-pointer'}`}>
    <input
      type="checkbox"
      id="deg"
      name="angleUnit"
      value="deg"
      checked={angleUnit === 'deg'}
      onChange={handleAngleUnitChange}
      className='cursor-pointer'
    />
    <label htmlFor="deg" className='cursor-pointer'>Deg</label>
  </div>


        <div className={`tab-button btn-sm cursor-pointer ${activeTab === 'tab1' ? 'bg-white-1 mr-1 btn-sm' : ''}`} onClick={() => openTab('tab1')}>
          Basic
        </div>

        <div className={`tab-button btn-sm cursor-pointer ${activeTab === 'tab2' ? 'bg-white-1 ml-1 btn-sm' : ''}`} onClick={() => openTab('tab2')}>
          Advance
        </div>
      </div>

      {activeTab === 'tab2' && (
        
        
          <div className="flex justify-between sm-block lg-gap md-block tab-content pt-2 pb-2 pl-1 pr-1">
          <div className='flex w-50 justify-center wrap sm-w-100 md-w-100'>
          <div className="flex justify-between">
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('sin(')}>sin</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('cos(')}>cos</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('tan(')}>tan</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('asin(')}>sin⁻¹</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('acos(')}>cos⁻¹</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('atan(')}>tan⁻¹</button>
        </div>
        <div className="flex justify-between">
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('xgcd(')}>XGCD</button>
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('gcd(')}>GCD</button>
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('lcm(')}>LCM</button>
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('zeta(')}>zeta</button>
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('erf(')}>erf</button>
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('log(')}>log</button>
        </div>
        <div className="flex justify-between">
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('hypot(')}>&#9651;</button>
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('gamma(')}>&#915;</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('e')}>e</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('e^')}>e<sup>x</sup></button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('10^')}>10<sup>x</sup></button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('^')}>x<sup>y</sup></button>
        </div>
        <div className="flex justify-between">
        <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('^(3)')}>x³</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('^(2)')}>x²</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('sqrt(')}>y√x</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('cbrt(')}>3√x</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('sqrt(')}>√x</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('nthRoot(')}><sup>n</sup>&radic;<span>x</span></button>
          </div>
        <div className="flex justify-between">
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('()')}>(</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('()')}>)</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('1/()')}>1/x</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('%')}>%</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('pi')}>π</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('factorial(')}>n!</button>
        </div>

        </div>
        <div className='flex w-50 justify-center wrap sm-w-100 md-w-90 mr-center'>

        <div className="flex justify-between">
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('7')}>7</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('8')}>8</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('9')}>9</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('+')}>+</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('back')}>Back</button>
        </div>
        <div className="flex justify-between">
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('4')}>4</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('5')}>5</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('6')}>6</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('-')}>-</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('M+')}>M+</button>
          
        </div>
        <div className="flex justify-between">
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('1')}>1</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('2')}>2</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('3')}>3</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('*')}>×</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('M-')}>M-</button>
        </div>
        <div className="flex justify-between">
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('0')}>0</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('.')}>.</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('*10^')}>EXP</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('/')}>/</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('MR')}>MR</button>
        </div>
        <div className="flex justify-between">
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('-1*')}>±</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('round(')}>RND</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={handleClear}>AC</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={handleCalculate}>=</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={MClear}>MC</button>
        </div>
        </div>
      </div>
      
      
      )}

      {activeTab === 'tab1' && (
        
      <div className="buttons flex justify-center p-1 rounded">
        <div>
<div className="flex justify-between wrap">
  <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('7')}>7</button>
  <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('8')}>8</button>
  <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('9')}>9</button>
  <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('+')}>+</button>
  <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => setExpression((prevExpression) => prevExpression.slice(0, -1))}>Back</button>
</div>
<div className="flex justify-between wrap">
  <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('4')}>4</button>
  <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('5')}>5</button>
  <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('6')}>6</button>
  <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('-')}>-</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('M+')}>M+</button>
          
        </div>
        <div className="flex justify-between">
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('1')}>1</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('2')}>2</button>
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('3')}>3</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('*')}>×</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('M-')}>M-</button>
        </div>
        <div className="flex justify-between">
          <button className="rounded bg-white text-black-1 border-1 cursor-pointer btn-sm-lt btn-w-50 mp-2" onClick={() => handleButtonClick('0')}>0</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('.')}>.</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('*10^')}>EXP</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('/')}>/</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('MR')}>MR</button>
        </div>
        <div className="flex justify-between">
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('-1*')}>±</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={() => handleButtonClick('round(')}>RND</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={handleClear}>AC</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={handleCalculate}>=</button>
          <button className="rounded bg-color text-white-1 border-1 btn-sm-lt cursor-pointer btn-w-50 mp-2" onClick={MClear}>MC</button>
        </div></div>
        </div>
        
      )}
      
    </div>
  );
};

export default ScientificCalculator;